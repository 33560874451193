import { Component, Fragment } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import store from 'store';

import { AuthProvider } from 'contexts/auth';

import { PUBLIC } from 'shared/panel-links';

// libs
import { getLang } from 'libs/cookies';
import { langFormar } from 'libs/methods';

// Component
import CustomHelmet from 'components/helmet';

// Language
import messages from 'i18n';

// templated
// Public
import PublicHome from 'templates/public/home';
import PublicRates from 'templates/public/rates';
import PublicProducts from 'templates/public/products';
import PublicWhiteBrand from 'templates/public/white_brand';
import PublicContact from 'templates/public/contact';
import PublicLegalRecord from 'templates/public/legal-record';
import PublicLegalAdvice from 'templates/public/Legal/LegalAdvice';
import PublicPrivacyPolicy from 'templates/public/Legal/PrivacyPolicy';
import PublicCookiePolicy from 'templates/public/Legal/CookiePolicy';
import PublicApiDocumentation from 'templates/public/api_documentation';
import PublicBrokerApiDocumentation from 'templates/public/broker_api_documentation';
// Panel
import Login from 'templates/login';
import EmailRecovery from 'templates/emailRecovery';
import RecoveryAccount from 'templates/recoveryAccount';
import Estadisticas from 'templates/estadisticas';
import Administracion from 'templates/administracion';
import Broker from 'templates/broker';
import Promocion from 'templates/promocion';
import Support from 'templates/support';
import Herramientas from 'templates/herramientas';
import Noticias from 'templates/news';
import AffiliateRegister from 'templates/affiliateRegister';
import StatsOlecams from 'templates/statsOlecams';
import StatsVce from 'templates/statsVce';
import NotFound from 'templates/notFound';
import AdminStats from 'templates/herramientas/Stats';
import AdminTools from 'templates/adminTools';
import Api from 'templates/api';

// styles
import './index.scss';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.lang = langFormar(getLang() ? getLang() : navigator.language);
  }

  render() {
    return (
      <>
        <CustomHelmet lang={this.lang} />
        <IntlProvider locale={this.lang} textComponent={Fragment} messages={messages[this.lang]}>
          <Provider store={store}>
            <AuthProvider>
              <BrowserRouter>
                <Switch>
                  <Route exact path="/" component={PublicHome} />
                  <Route exact path={PUBLIC.HOME} component={PublicHome} />
                  <Route exact path={PUBLIC.RATES} component={PublicRates} />
                  <Route exact path={PUBLIC.PRODUCTS} component={PublicProducts} />
                  <Route exact path={PUBLIC.WHITE_BRAND} component={PublicWhiteBrand} />
                  <Route exact path={PUBLIC.CONTACT} component={PublicContact} />
                  <Route exact path={PUBLIC.LEGAL_RECORD} component={PublicLegalRecord} />
                  <Route exact path={PUBLIC.LEGAL_ADVICE} component={PublicLegalAdvice} />
                  <Route exact path={PUBLIC.PRIVACY_POLICY} component={PublicPrivacyPolicy} />
                  <Route exact path={PUBLIC.COOKIE_POLICY} component={PublicCookiePolicy} />

                  <Route exact path={PUBLIC.API_DOCS} component={PublicApiDocumentation} />
                  <Route exact path={PUBLIC.BROKERS_API_DOCS} component={PublicBrokerApiDocumentation} />
                  <Route path={PUBLIC.LOGIN} component={Login} />
                  <Route path={PUBLIC.EMAIL_RECOVERY} component={EmailRecovery} />
                  <Route path={PUBLIC.RECOVERY + '/:token'} component={RecoveryAccount} />
                  <Route exact path="/estadisticas" component={Estadisticas} />
                  <Route exact path="/estadisticas/vce" component={StatsVce} />
                  <Route exact path="/estadisticas/olecams" component={StatsOlecams} />
                  <Route exact path="/estadisticas/olecams/register-day/:date" component={StatsOlecams} />
                  <Route exact path="/estadisticas/olecams/register-day/:date/:valid" component={StatsOlecams} />
                  <Route exact path="/estadisticas/olecams/stats-day/:date" component={StatsOlecams} />
                  <Route exact path="/estadisticas/olecams/stats-day/:date/:param" component={StatsOlecams} />
                  <Route exact path="/estadisticas/olecams/stats-user/:email" component={StatsOlecams} />
                  <Route exact path="/estadisticas/olecams/phone" component={StatsOlecams} />
                  <Route exact path="/estadisticas/olecams/sms" component={StatsOlecams} />
                  <Route path="/administracion/:section" component={Administracion} />
                  <Route path="/broker/:section/:part?" component={Broker} />
                  <Route path={'/promocion/:section/:part?'} component={Promocion} />
                  <Route exact path="/administration" component={AdminTools} />
                  <Route exact path="/herramientas-administrativas/:section/:part?" component={Herramientas} />
                  <Route exact path="/admin" component={AdminStats} />
                  <Route exact path="/admin/stats" component={AdminStats} />
                  {/* <Route exact path="/admin/stats/olecams" component={AdminStats} />
                  <Route exact path="/admin/stats/olecams/phone" component={AdminStats} /> */}
                  <Route exact path="/admintools/stats/olecams" component={AdminStats} />
                  <Route exact path="/admintools/stats/olecams/phone" component={AdminStats} />
                  <Route exact path="/admintools/stats/olecams/sms" component={AdminStats} />
                  <Route exact path="/admintools" component={AdminTools} />
                  <Route path="/admintools/:section/:part?" component={AdminTools} />
                  <Route path="/noticias" component={Noticias} />
                  <Route path="/soporte" component={Support} />
                  <Route path="/api" component={Api} />
                  <Route path={PUBLIC.REGISTER} component={AffiliateRegister} />
                  <Route component={NotFound} />
                </Switch>
              </BrowserRouter>
            </AuthProvider>
          </Provider>
        </IntlProvider>
      </>
    );
  }
}

export default App;
