import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Col, Form, Row, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faSave, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import translate from 'i18n/translate';

// Hooks
import useValidator from 'hooks/useValidator';

import WhiteLabelsService from 'shared/white-labels-service';

//import Favicons from './favicons';

//libs
import { cleanString } from 'libs/methods';
import { getLang } from 'libs/cookies';
import { debounce } from 'libs/lodashAlt';

import Preview from 'components/whiteLabels/preview';
import CustomButton from 'components/button';
import Loading from 'components/loading';

import DomainsList from './domainsList';
import Social from './social';
import Legal from './legal';
import Analitic from './analitic';
import NewMetatagSection from 'components/whiteLabels/NewMetatagSection';

// style
import './index.scss';

const WhiteBrandForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch({ type: 'SET_LOADING', loading: false });
  }, [dispatch]);

  //dispatch({ type: 'SET_LOADING', loading: false });
  const auth = useSelector((state) => state.auth.auth);
  const prefix_afno = '1-' + auth.id_affiliate + '-';

  const [name, setName] = useState('');
  // Social
  const [twitter, setTwitter] = useState('');
  const [instagram, setInstagram] = useState('');
  const [social_default_image, setSocialDefaultImage] = useState(null);
  const [afno, setAfno] = useState('');

  const [notifications_email, setNotificationsEmail] = useState('');

  const [logo, setLogo] = useState(null);

  const [primary_color, setPrimaryColor] = useState('#fc531d');

  const [domain_owner_name, setDomainOwnerName] = useState('');
  const [domain_owner_email, setDomainOwnerEmail] = useState('');

  const [use_live_thumbs, setUseLiveThumbs] = useState(true);

  const [domain, setDomain] = useState('');
  const [domains, setDomains] = useState([]);

  const [favicon, setFavicon] = useState(null);

  const [gtm, setGTM] = useState('');
  const [ga, setGA] = useState('');

  const [metatagsItems, setMetatagsItems] = useState({});

  let loading = useSelector((state) => state.loading.loading);
  const intl = useIntl();

  const [validator, showValidationMessage] = useValidator({}, {}, getLang());

  // Functions
  const saveTwitter = (e) => setTwitter(e.target.value);
  const saveInstagram = (e) => setInstagram(e.target.value);
  const saveSocialDefaultImage = (e) => setSocialDefaultImage(e.target.value);
  const savePrimaryColor = (e) => setPrimaryColor(e.target.value);
  const saveDomainEmail = (e) => setDomainOwnerEmail(e.target.value);
  const saveDomainName = (e) => setDomainOwnerName(e.target.value);

  const saveImage = (event, type) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].type.includes('image')) {
        if (!event.target.files[0].type.includes('webp')) {
          if (event.target.files[0].size <= 2097152) {
            // CHECK MAX SIZE
            switch (type) {
              case 'logo':
                setLogo(event.target.files[0]);
                break;
              case 'favicon':
                // Check if is a PNG
                //if (!event.target.files[0].type.includes('svg')){
                // Check Favicon Size
                var img = document.createElement('img');
                img.onload = function () {
                  //debugger;
                  //console.log(this.width + " " + this.height);
                  if (this.width < 128 || this.height < 128) {
                    toast.error(translate('global-image-size-too-small-128'));
                    setFavicon(null);
                  }
                };
                var reader = new FileReader();
                reader.onloadend = function (ended) {
                  img.src = ended.target.result;
                };
                reader.readAsDataURL(event.target.files[0]);
                setFavicon(event.target.files[0]); // Always set favicon but after that checks the minimum size
                break;
              case 'opengraph':
                setSocialDefaultImage(event.target.files[0]);
                break;
              default:
                setLogo(event.target.files[0]);
                break;
            }
          } else toast.error(translate('global-file-too-big'));
        } else toast.error(translate('global-file-image-type-not-allowed'));
      } else toast.error(translate('global-file-must-be-image'));
    }
  };

  const handleTabChange = (key, event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const debouncedOnChangeColor = debounce((name, value) => {
    savePrimaryColor({ target: { name, value } });
    //handleOnChange({target: { name, value }}, 'designForm');
    //console.log(value);
  }, 150);

  const create = () => {
    let config = {
      NAME: name,
      TWITTER: twitter,
      INSTAGRAM: instagram,
      AFNO: prefix_afno + afno,
      NOTIFICATIONS_EMAIL: notifications_email,
      PRIMARY_COLOR: primary_color,
      DOMAIN_OWNER_NAME: domain_owner_name,
      DOMAIN_OWNER_EMAIL: domain_owner_email,
      GTM: gtm,
      GA: ga,
    };

    if (validator.allValid()) {
      dispatch({ type: 'SET_LOADING', loading: true });
      WhiteLabelsService.set(config, domains, metatagsItems, logo, favicon, social_default_image)
        .then((responseData) => {
          if (responseData.status === 'Failed') toast.error(translate('promo-white-brand-failed-created'));
          else {
            toast.success(translate('promo-white-brand-created'));
            history.push('/promocion/marca-blanca/');
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    } else showValidationMessage(true);
  };

  if (loading) return <Loading />;

  return (
    <Row className="main-white-brand container-fluid">
      <Col md="12">
        <Card className="mb-2">
          <Card.Header>
            <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> {translate('promo-setup-white-brand')}{' '}
          </Card.Header>
          <Card.Body>
            <Form id="form" className="form">
              <Row>
                <Col xl="6">
                  <Form.Group as={Row} className="mb-3 required" controlId="formName">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-top`}>{translate('herramientas-white-label-name-explanation')}</Tooltip>}>
                      <Form.Label column sm="3" className="font-weight-bold">
                        <span className="text">{translate('global-name')}</span>
                        <span className="help-icon float-right">
                          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                        </span>
                      </Form.Label>
                    </OverlayTrigger>
                    <Col sm="9">
                      <Form.Control value={name} name="name" placeholder="" onChange={(e) => setName(e.target.value)} />
                      {validator.message(intl.formatMessage({ id: 'global-name' }), name, 'max: 255|required')}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 required" controlId="formAfno">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-top`}>{translate('herramientas-white-label-tracking-id-explanation')}</Tooltip>}>
                      <Form.Label column sm="3" className="font-weight-bold">
                        <span className="text">{translate('global-campaign-id')}</span>
                        <span className="help-icon float-right">
                          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                        </span>
                      </Form.Label>
                    </OverlayTrigger>

                    <Col sm="9">
                      <InputGroup className="">
                        <InputGroup.Text>{prefix_afno}</InputGroup.Text>
                        <Form.Control
                          className="w-auto f-left"
                          value={afno}
                          placeholder=""
                          name="afno"
                          onChange={(e) => setAfno(cleanString(e.target.value))}
                        />
                      </InputGroup>

                      {validator.message(intl.formatMessage({ id: 'global-campaign-id' }), afno, 'max: 150|required')}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 required" controlId="formNotificationsEmail">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>{translate('herramientas-white-label-email-notification-explanation')}</Tooltip>
                      }>
                      <Form.Label column sm="3" className="font-weight-bold">
                        <span className="text">{translate('global-notification-email')}</span>
                        <span className="help-icon float-right">
                          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                        </span>
                      </Form.Label>
                    </OverlayTrigger>
                    <Col sm="9">
                      <Form.Control
                        value={notifications_email}
                        name="notifications_email"
                        placeholder=""
                        onChange={(e) => setNotificationsEmail(e.target.value)}
                      />
                      {validator.message(
                        intl.formatMessage({ id: 'global-notification-email' }),
                        notifications_email,
                        'max: 255|email|required'
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formLogo">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-top`}>{translate('herramientas-white-label-logo-explanation')}</Tooltip>}>
                      <Form.Label column sm="3" className="font-weight-bold">
                        <span className="text">Logo</span>
                        <span className="help-icon float-right">
                          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                        </span>
                      </Form.Label>
                    </OverlayTrigger>
                    <Col sm="9">
                      <Form.Control
                        accept="image/*"
                        className="form-control"
                        type="file"
                        name="logo"
                        onChange={(e) => saveImage(e, 'logo')}
                      />
                      {validator.message('logo', logo, 'required')}
                    </Col>
                  </Form.Group>
                  {logo && (
                    <Row className="text-center mb-2">
                      <Col sm="12">
                        <img style={{ maxWidth: '128px' }} src={URL.createObjectURL(logo)} className={'img-preview'} alt="" />
                      </Col>
                    </Row>
                  )}

                  <Form.Group as={Row} className="mb-3" controlId="formFavicon">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-top`}>{translate('herramientas-white-label-favicon-explanation')}</Tooltip>}>
                      <Form.Label column sm="3" className="font-weight-bold">
                        <span className="text">Favicon</span>
                        <span className="help-icon float-right">
                          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                        </span>
                      </Form.Label>
                    </OverlayTrigger>

                    <Col sm="9">
                      <Form.Control
                        accept="image/*"
                        className="form-control"
                        type="file"
                        name="favicon"
                        onChange={(e) => saveImage(e, 'favicon')}
                      />
                      {validator.message('favicon', favicon, 'required')}
                    </Col>
                  </Form.Group>

                  {favicon && (
                    <Row className="text-center mb-2">
                      <Col sm="12">
                        <img style={{ maxWidth: '128px' }} src={URL.createObjectURL(favicon)} className={'img-preview'} alt="" />
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col xl>
                      <Form.Group as={Row} className="mb-3" controlId="formPrimaryColor">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id={`tooltip-top`}>{translate('herramientas-white-label-primary-color-explanation')}</Tooltip>}>
                          <Form.Label column sm="6" className="font-weight-bold">
                            <span className="text">{translate('global-primary-color')}</span>
                            <span className="help-icon float-right">
                              <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                            </span>
                          </Form.Label>
                        </OverlayTrigger>
                        <Col sm="6">
                          <Form.Control
                            type="color"
                            name="primary_color"
                            value={primary_color}
                            title="Seleccione el color"
                            onChange={(e) => debouncedOnChangeColor(e.nativeEvent.target.name, e.nativeEvent.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xl>
                      <Form.Group as={Row} className="mb-3" controlId="formUseLiveThumbs">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id={`tooltip-top`}>{translate('herramientas-white-label-live-thumb-explanation')}</Tooltip>}>
                          <Form.Label column sm="6" className="font-weight-bold">
                            <span className="text">{translate('global-use-live-thumbs')}</span>
                            <span className="help-icon float-right">
                              <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                            </span>
                          </Form.Label>
                        </OverlayTrigger>

                        <Col sm="6">
                          <Form.Check
                            onChange={(e) => setUseLiveThumbs(e.target.checked)}
                            type="checkbox"
                            inline
                            checked={use_live_thumbs}
                            name="useLiveThumbs"
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Social
                    twitter={twitter}
                    saveTwitter={saveTwitter}
                    instagram={instagram}
                    saveInstagram={saveInstagram}
                    social_default_image={social_default_image}
                    saveSocialDefaultImage={saveSocialDefaultImage}
                    saveImage={saveImage}
                    validator={validator}
                    edit={false}
                  />
                  <Analitic gtm={gtm} ga={ga} setGTM={setGTM} setGA={setGA} />
                </Col>
                <Col xl="6">
                  <DomainsList
                    domain={domain}
                    setDomain={setDomain}
                    domains={domains}
                    setDomains={setDomains}
                    edit={false}
                    validator={validator}
                  />
                  <Legal
                    domain_owner_name={domain_owner_name}
                    saveDomainName={saveDomainName}
                    domain_owner_email={domain_owner_email}
                    saveDomainEmail={saveDomainEmail}
                    validator={validator}
                  />
                </Col>
              </Row>
              <NewMetatagSection metatagsItems={metatagsItems} setMetatagsItems={setMetatagsItems} />
              <Preview logo={logo} color={primary_color} />
              <Row>
                <Col>
                  <CustomButton rounded className="w-100" onClick={create}>
                    <FontAwesomeIcon icon={faSave} className="mr-2" />
                    <span>
                      <FormattedMessage id="administration-data-button" />
                    </span>
                  </CustomButton>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default WhiteBrandForm;
