import { useState } from 'react';
import { Card, Col, Form, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import translate from 'i18n/translate';

import WhiteLabelsService from 'shared/white-labels-service';
import Cname from 'components/whiteLabels/cnameDns';

const Domains = ({ domain, setDomain, edit, validator }) => {
  const intl = useIntl();
  //const saveDomain = (e) => setDomain(e.target.value);
  const onKeyDownHandler = (e) => saveDomainOnList(e.target.value);
  //const saveDomainOnBlur = (e) => (domain !== '') && saveDomainOnList(e.target.value);

  const saveDomainOnList = (domain) => {
    setDomainInvalid(false);
    setDomain(domain);
  };

  const [domainInvalid, setDomainInvalid] = useState(false);

  const checkDomainOnBlur = (e) => {
    let _domain = e.target.value;
    WhiteLabelsService.checkDomain(_domain).then((responseData) => {
      if (responseData.status === 'Accepted' && responseData.message) {
        // if false domain dont exists
        setDomainInvalid(true);
        toast.error(translate('promo-domain-exists'));
      }
    });
  };

  return (
    <Card className="mb-2">
      <Card.Header>{translate('global-domain')}s</Card.Header>
      <Card.Body>
        {!edit && (
          <>
            <Form.Group as={Row} className="mb-3 required" controlId="formDomains">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-top`}>{translate('herramientas-white-label-domains-explanation')}</Tooltip>}>
                <Form.Label column sm="3" className="font-weight-bold">
                  <span className="text">{translate('global-domain')}</span>
                  <span className="help-icon float-right">
                    <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                  </span>
                </Form.Label>
              </OverlayTrigger>
              <Col sm="9" className="d-flex">
                <Form.Control
                  value={domain}
                  name="domains"
                  placeholder="www.domain.com"
                  onChange={onKeyDownHandler}
                  //onKeyDown={onKeyDownHandler}
                  onBlur={checkDomainOnBlur}
                  isInvalid={domainInvalid}
                />
              </Col>
            </Form.Group>
            <Row>
              <Col>{validator.message(intl.formatMessage({ id: 'global-domain' }), domain, 'max: 255|domain|required')}</Col>
            </Row>
          </>
        )}
        {edit && (
          <>
            <Form.Group as={Row} className="mb-3 required" controlId="formDomains">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-top`}>{translate('herramientas-white-label-domains-explanation')}</Tooltip>}>
                <Form.Label column sm="3" className="font-weight-bold">
                  <span className="text">{translate('global-domain')}</span>
                  <span className="help-icon float-right">
                    <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                  </span>
                </Form.Label>
              </OverlayTrigger>
              <Col sm="9" className="d-flex">
                <Form.Control
                  value={domain}
                  name="domains"
                  placeholder="www.domain.com"
                  disabled
                  //onKeyDown={onKeyDownHandler}
                />
              </Col>
            </Form.Group>
            <Row>
              <Col>{validator.message(intl.formatMessage({ id: 'global-domain' }), domain, 'max: 255|domain|required')}</Col>
            </Row>
          </>
        )}

        <hr />
        <Row>
          <Col sm="12" className="text-center">
            <p>{translate('herramientas-white-label-add-domains-subdomains')}</p>
            <Row>
              <Col className="text-center">
                <span className="font-weight-bold">{translate('herramientas-white-label-example-domain')}</span>
                <br />
                <div className="underlined orange font-weight-bold">www.example.com</div>
              </Col>
              <Col className="text-center">
                <span className="font-weight-bold">{translate('herramientas-white-label-example-subdomain')}</span>
                <br />
                <div className="underlined orange font-weight-bold">name.example.com</div>
              </Col>
              <br />
            </Row>

            <br />

            <p>{translate('herramientas-white-label-remember-cname')}</p>
            <Cname />
          </Col>
        </Row>
      </Card.Body>
      {/*<Card.Footer><small>{translate('promo-domains-as-needed')}</small></Card.Footer>*/}
    </Card>
  );
};

export default Domains;
