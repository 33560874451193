import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { getLang } from 'libs/cookies';
import { dateFormat } from 'libs/methods';

//components
import SelectProvider from 'templates/statsOlecams/formComponents/SelectProvider';
import SelectPaymentType from 'templates/statsOlecams/formComponents/SelectPaymentType';
import SelectPurchaseFirst from 'templates/statsOlecams/formComponents/SelectPurchaseFirst';
import SelectDevice from 'templates/statsOlecams/formComponents/SelectDevice';
import SelectCountry from 'templates/statsOlecams/formComponents/SelectCountry';
import SelectSitemode from 'templates/statsOlecams/formComponents/SelectSitemode';
import SelectWhiteLabel from 'templates/statsOlecams/formComponents/SelectWhiteLabel';
import SelectAffiliate from 'templates/statsOlecams/formComponents/SelectAffiliate';
import SelectFidelity from 'templates/statsOlecams/formComponents/SelectFidelity';
import SelectStatus from 'templates/statsOlecams/formComponents/SelectStatus';
import InputAttempts from 'templates/statsOlecams/formComponents/InputAttempts';
import SelectRegisteredFidelity from 'templates/statsOlecams/formComponents/SelectRegisteredFidelity';

import Button from 'components/button';
import IconCalendar from 'components/icons/calendar';

const Form = (props) => {
  const auth = useSelector((state) => state.auth.auth);
  const loading = useSelector((state) => state.loading.loading);
  const history = useHistory();
  const { formatMessage } = useIntl();
  registerLocale('es', es);
  return (
    <form className="form-content" onSubmit={props.getStats}>
      <div className="form-detall-content">
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
            <div className="form-group calendar">
              <label>
                <FormattedMessage id="statistics-form-date" />
              </label>
              <div className="form-control">
                <DatePicker
                  selected={dateFormat(props.filters.date)}
                  onChange={(date) => props.handleChangeDate(date)}
                  locale={getLang()}
                  dateFormat="dd/MM/yyyy"
                  id="calendar-day"
                  popperPlacement="bottom"
                />
                <IconCalendar id="calendar-day" />
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
            <SelectPaymentType value={props.filters.payment_type} handleChangePay={props.handleOnChange} />
          </div>

          {parseInt(auth.admin) === 1 && (
            <>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                <SelectProvider value={props.filters.provider} onChange={props.handleOnChange} paymentValue={props.filters.payment_type} />
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                <SelectPurchaseFirst value={props.filters.first_purchase} handleChangeFirstPurchase={props.handleOnChange} />
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                <SelectSitemode value={props.filters.sitemode} handleChange={props.handleOnChange} />
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                <SelectWhiteLabel whitelabelId={props.filters.whitelabel} handleChange={props.handleOnChange} />
              </div>
            </>
          )}
          <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
            <SelectCountry value={props.filters.country} handleChangeCountry={props.handleOnChange} />
          </div>
          <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
            <SelectDevice value={props.filters.device} handleChangeDevice={props.handleOnChange} />
          </div>
          <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
            <div className="form-group">
              <label>Tracking ID</label>
              <input
                placeholder={formatMessage({ id: 'statistics-form-select-all' })}
                type="text"
                name="afno"
                className="form-control"
                onChange={props.handleOnChange}
                value={props.filters.afno}
              />
            </div>
          </div>
          {parseInt(auth.admin) === 1 && (
            <>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                <SelectAffiliate affiliateId={props.filters.affiliate_id} handleChange={props.handleOnChange} />
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                <SelectFidelity value={props.filters.anonymous_fidelity} handleChange={props.handleOnChange} />
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                <SelectRegisteredFidelity value={props.filters.registered_fidelity} onChange={props.handleOnChange} />
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                <SelectStatus value={props.filters.status} handleChange={props.handleOnChange} />
              </div>

              <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                <InputAttempts value={props.filters.attempt} onChange={props.handleOnChange} />
              </div>

              <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                <div className="form-group">
                  <label htmlFor="discounts">
                    <FormattedMessage id="global-discounts-only" />
                  </label>
                  <input
                    type="checkbox"
                    id="discounts"
                    name="discounts"
                    className="form-control check"
                    onChange={props.handleOnChange}
                    checked={props.filters.discounts}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="buttons-content stats-day">
          <Button rounded onClick={(e) => history.goBack()} align="center" className="left">
            <FormattedMessage id="statistics-form-button-return" />
          </Button>
          <Button rounded onClick={props.getStats} align="center" type="submit" disabled={loading}>
            {loading ? <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" /> : <FormattedMessage id="global-send" />}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Form;
