import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

//libs
import { formatPrice } from 'libs/methods';

//style
import '../index.scss';

const Table = ({ stats }) => {
  const auth = useSelector((state) => state.auth.auth);
  const history = useHistory();
  return (
    <table className="table sp">
      <thead>
        <tr>
          <th scope="col">
            <FormattedMessage id="statistics-table-date" />
          </th>
          <th scope="col">
            <FormattedMessage id="statistics-table-register" />
          </th>
          {parseInt(auth.admin) === 1 && (
            <>
              <th scope="col">
                <FormattedMessage id="statistics-table-frau" />
              </th>
              <th scope="col">
                <FormattedMessage id="statistics-table-valid" />
              </th>
              <th scope="col">
                <FormattedMessage id="sidebar-setting-discounts" />
              </th>
              <th scope="col">Fidelidad Registrado</th>
            </>
          )}
          <th scope="col">
            <FormattedMessage id="statistics-table-first" />
          </th>
          <th scope="col">
            <FormattedMessage id="statistics-table-refact" />
          </th>
          <th scope="col">
            <FormattedMessage id="statistics-table-gan" />
          </th>
        </tr>
      </thead>
      <tbody>
        {stats.results.map((stats, index) => {
          let tipo = (index + 1) % 2 ? 'impar' : 'par';
          return (
            <tr key={index} className={tipo}>
              <td onClick={(e) => history.push(`/estadisticas/olecams/stats-day/${stats.DATE}`)}>{stats.DATE}</td>
              <td onClick={(e) => history.push(`/estadisticas/olecams/register-day/${stats.DATE}`)}>{stats.REGISTER_SHOTS}</td>
              {parseInt(auth.admin) === 1 && (
                <>
                  <td onClick={(e) => history.push(`/estadisticas/olecams/register-day/${stats.DATE}/1`)}>{stats.FRAUDULENT_DOI}</td>
                  <td onClick={(e) => history.push(`/estadisticas/olecams/register-day/${stats.DATE}/1`)}>{stats.VALIDATED_DOI}</td>
                  <td onClick={(e) => history.push(`/estadisticas/olecams/stats-day/${stats.DATE}`)}>
                    <span>{stats.TOTAL_DISCOUNTS_COUNT}</span>
                    <span>{formatPrice(stats.TOTAL_DISCOUNTS_AMOUNT)}</span>
                  </td>
                  <td onClick={(e) => history.push(`/estadisticas/olecams/stats-day/${stats.DATE}`)}>
                    <span>{stats.TOTAL_REGISTERED_FIDELITY_COUNT}</span>
                    <span>{formatPrice(stats.TOTAL_REGISTERED_FIDELITY_AMOUNT)}</span>
                  </td>
                </>
              )}
              <td onClick={(e) => history.push(`/estadisticas/olecams/stats-day/${stats.DATE}/first`)}>
                <span>{stats.FIRST_PURCHASES_COUNT}</span>
                <span>{formatPrice(stats.FIRST_PURCHASES_AMOUNT)}</span>
              </td>
              <td onClick={(e) => history.push(`/estadisticas/olecams/stats-day/${stats.DATE}/upsell`)}>
                <span>{stats.UPSELL_PURCHASES_COUNT}</span>
                <span>{formatPrice(stats.UPSELL_PURCHASES_AMOUNT)}</span>
              </td>
              <td onClick={(e) => history.push(`/estadisticas/olecams/stats-day/${stats.DATE}`)}>
                <span>{stats.TOTAL_PURCHASES_COUNT}</span>
                <span>{formatPrice(stats.TOTAL_PURCHASES_AMOUNT)}</span>
              </td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <th scope="col">
            <FormattedMessage id="statistics-table-total" />
          </th>
          <th scope="col">{stats.summary.signin}</th>
          {parseInt(auth.admin) === 1 && (
            <>
              <th scope="col">{stats.summary.fraudulentDoi}</th>
              <th scope="col">{stats.summary.validatedDoi}</th>
              <th scope="col">
                <span>{stats.summary.totalDiscountsCount}</span>
                <span>{formatPrice(stats.summary.totalDiscountsAmount)}</span>
              </th>
              <th scope="col">
                <span>{stats.summary.totalRegisteredFidelityCount}</span>
                <span>{formatPrice(stats.summary.totalRegisteredFidelityAmount)}</span>
              </th>
            </>
          )}
          <th scope="col">
            <span>{stats.summary.firstPurchasesCount}</span>
            <span>{formatPrice(stats.summary.firstPurchasesAmount)}</span>
          </th>
          <th scope="col">
            <span>{stats.summary.upsellPurchasesCount}</span>
            <span>{formatPrice(stats.summary.upsellPurchasesAmount)}</span>
          </th>
          <th scope="col">
            <span>{stats.summary.totalPurchasesCount}</span>
            <span>{formatPrice(stats.summary.totalPurchasesAmount)}</span>
          </th>
        </tr>
      </tfoot>
    </table>
  );
};

export default Table;
