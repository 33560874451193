import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Container, Col, Form, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import AffiliatesRatesService from 'shared/affiliates-rates-service';

import { isEmpty } from 'libs/lodashAlt';

import Button from 'components/button';
import CustomCard from 'components/card';
import IconData from 'components/icons/data';

const AffiliatesRates = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    affiliate_id: '',
  });
  const [data, setData] = useState([]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const sendSubmit = (e) => {
    e.preventDefault();
    if (filters.affiliate_id === '') return;
    setData([]);
    dispatch({ type: 'SET_LOADING', loading: true });
    AffiliatesRatesService.adminList(filters)
      .then((responseData) => {
        responseData.status === 'Failed' && toast.error('No se pudo cargar las tarifas del afiliado, puede que el afiliado no exista');
        responseData.status === 'Accepted' && setData(responseData.message);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  return (
    <Container className="mw-100 ha-discounts">
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>Editor Tarifas de Afiliado</Card.Header>
            <Card.Body>
              <FormFilters filters={filters} handleOnChange={handleOnChange} sendSubmit={sendSubmit} />
              <br />
              {data && data.oc && data.vce ? <DataTables data={data} setData={setData} filters={filters} /> : <>No hay datos</>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const FormFilters = ({ filters, handleOnChange, sendSubmit }) => {
  const loading = useSelector((state) => state.loading.loading);
  return (
    <Row className="justify-content-between">
      <Col>
        <form className={`search-wrapper`} onSubmit={sendSubmit}>
          <div className="first-filters d-flex align-center">
            <Form.Group className="mr-3" controlId="formSearch">
              <Form.Label>ID Afiliado</Form.Label>
              <Form.Control
                value={filters.affiliate_id}
                name="affiliate_id"
                placeholder="Id de afiliado"
                onChange={handleOnChange}
                required
                type="number"
              />
            </Form.Group>
          </div>
          <div className="buttons-content">
            <Button rounded disabled={loading} type="submit">
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
              ) : (
                <>
                  <FontAwesomeIcon icon={faSearch} className="mr-2" /> <FormattedMessage id="global-send" />
                </>
              )}
            </Button>
          </div>
        </form>
      </Col>
    </Row>
  );
};

const DataTables = ({ data, setData, filters }) => {
  const dispatch = useDispatch();
  const { vce, oc } = data;

  const [ocPorcentaje, setOcPorcentaje] = useState(!isEmpty(oc.porcentaje) ? oc.porcentaje : []);
  const [ocEscalado, setOcEscalado] = useState(!isEmpty(oc.escalado) ? oc.escalado : []);
  const [vceEscalado, setVceEscalado] = useState(!isEmpty(vce.escalado) ? vce.escalado : []);
  const [vcePacks, setVcePacks] = useState(!isEmpty(vce.packs) ? vce.packs : []);
  const [vceOperadoras, setVceOperadoras] = useState(!isEmpty(vce.operadoras) ? vce.operadoras : []);

  const submit = async (e) => {
    e.preventDefault();
    dispatch({ type: 'SET_LOADING', loading: true });
    await AffiliatesRatesService.adminUpdate({
      ocPorcentaje,
      ocEscalado,
      vceEscalado,
      vcePacks,
      vceOperadoras,
    })
      .then((data) => {
        data.status === 'Accepted' && toast.success('Datos Actualizados');
        data.status === 'Failed' && toast.error('Ocurrió un error');
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));

    setData([]);
    if (filters.affiliate_id === '') return;

    dispatch({ type: 'SET_LOADING', loading: true });
    await AffiliatesRatesService.adminList(filters)
      .then((responseData) => {
        responseData.status === 'Failed' && toast.error('No se pudo cargar las tarifas del afiliado, puede que el afiliado no exista');
        responseData.status === 'Accepted' && setData(responseData.message);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const handleOcPorcentaje = (index) => (event) => {
    const { name, value } = event.target;
    setOcPorcentaje((values) =>
      values.map((element, _index) =>
        index === _index
          ? {
              ...element,
              [name]: value,
            }
          : element
      )
    );
  };

  const handleOcEscalado = (index) => (event) => {
    const { name, value } = event.target;
    setOcEscalado((values) =>
      values.map((element, _index) =>
        index === _index
          ? {
              ...element,
              [name]: value,
            }
          : element
      )
    );
  };

  const handleVceEscalado = (index) => (event) => {
    const { name, value } = event.target;
    setVceEscalado((values) =>
      values.map((element, _index) =>
        index === _index
          ? {
              ...element,
              [name]: value,
            }
          : element
      )
    );
  };

  const handleVcePacks = (index) => (event) => {
    const { name, value } = event.target;
    setVcePacks((values) =>
      values.map((element, _index) =>
        index === _index
          ? {
              ...element,
              [name]: value,
            }
          : element
      )
    );
  };

  const handleVceOperadoras = (index) => (event) => {
    const { name, value } = event.target;
    setVceOperadoras((values) =>
      values.map((element, _index) =>
        index === _index
          ? {
              ...element,
              [name]: value,
            }
          : element
      )
    );
  };

  return (
    <>
      <OcRates data={ocPorcentaje} handleData={handleOcPorcentaje} />
      <OcPhoneRates data={ocEscalado} handleData={handleOcEscalado} />
      <VceRates data={vceEscalado} handleData={handleVceEscalado} />
      <VceVisaRates data={vcePacks} handleData={handleVcePacks} />
      {/* <VceSmsRates data={vceOperadoras} /> */}
      <VceSmsRates data={vceOperadoras} handleData={handleVceOperadoras} /> {/* The same as VCE sms rates */}
      <Row>
        <Col className="text-center">
          <Button onClick={submit}>
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            <FormattedMessage id="administration-data-button" />
          </Button>
        </Col>
      </Row>
    </>
  );
};

const OcRates = ({ data, handleData }) => {
  const { formatMessage } = useIntl();
  return (
    <CustomCard icon={<IconData />} title={formatMessage({ id: 'administration-rate-name' }, { type: 'OleCams' })}>
      <div className="table-content">
        <Table responsive="sm" striped bordered hover>
          <thead>
            <tr>
              <th scope="col">{formatMessage({ id: 'administration-rate-percent' })}</th>
              <th scope="col">€</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(data) ? (
              data.map((item, index) => {
                return (
                  <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                    <td>{item.name}</td>
                    <td>
                      <input name="price" value={item.price} onChange={handleData(index)} />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </CustomCard>
  );
};

const OcPhoneRates = ({ data, handleData }) => {
  const { formatMessage } = useIntl();
  return (
    <CustomCard header={false} table>
      <div className="table-content">
        <table className="table sp">
          <thead>
            <tr>
              <th scope="col">{formatMessage({ id: 'administration-rate-line-oc' })}</th>
              <th scope="col">{formatMessage({ id: 'administration-rate-euros-minutes' })}</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(data) ? (
              data.map((item, index) => {
                return (
                  <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                    <td>{item.name}</td>
                    <td>
                      <input name="price" value={item.price} onChange={handleData(index)} />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </CustomCard>
  );
};

const VceRates = ({ data, handleData }) => {
  const { formatMessage } = useIntl();
  return (
    <CustomCard icon={<IconData />} title={formatMessage({ id: 'administration-rate-name' }, { type: 'Videochaterotico' })} table>
      <div className="table-content">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">{formatMessage({ id: 'administration-rate-scaled' })}</th>
              <th scope="col">{formatMessage({ id: 'administration-rate-minut' })}</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(data) ? (
              data.map((item, index) => {
                return (
                  <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                    <td>{item.name}</td>
                    <td>
                      <input name="price" value={item.price} onChange={handleData(index)} />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </CustomCard>
  );
};

const VceVisaRates = ({ data, handleData }) => {
  const { formatMessage } = useIntl();
  return (
    <CustomCard header={false} table>
      <div className="table-content">
        <table className="table sp">
          <thead>
            <tr>
              <th scope="col">{formatMessage({ id: 'administration-rate-packs' })}</th>
              <th scope="col">{formatMessage({ id: 'administration-rate-visa' })}</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(data) ? (
              data.map((item, index) => {
                return (
                  <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                    <td>{item.name}</td>
                    <td>
                      <input name="price" value={item.price} onChange={handleData(index)} />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </CustomCard>
  );
};

const VceSmsRates = ({ data, handleData }) => {
  const { formatMessage } = useIntl();
  return (
    <CustomCard icon={<IconData />} title={formatMessage({ id: 'administration-rate-name' }, { type: 'Comunes' })} table>
      <div className="table-content">
        <table className="table sp">
          <thead>
            <tr>
              <th scope="col">{formatMessage({ id: 'administration-rate-oper' })}</th>
              <th scope="col">{formatMessage({ id: 'administration-rate-sms' })}</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(data) ? (
              data.map((item, index) => {
                return (
                  <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                    <td>{item.name}</td>
                    <td>
                      <input name="price" value={item.price} onChange={handleData(index)} />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </CustomCard>
  );
};

export default AffiliatesRates;
