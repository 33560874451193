import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faStar } from '@fortawesome/free-solid-svg-icons';

import OcStatsService from 'shared/oc-stats-service';

//libs
import { dateFormat, formatPrice } from 'libs/methods';
import { isEmpty } from 'libs/lodashAlt';

//Component
import Card from 'components/card';

import Source from '../source';
import Form from './form';
/*import TableYear from './tableYear'
import TableMonth from './tableMonth'*/
import TableDays from './tableDays';

import IconStatsOC from 'components/icons/statsOlecams';
import IconFirstBuy from 'components/icons/firstBuy';
import IconRefactor from 'components/icons/refactor';
import IconUserDesktop from 'components/icons/userDesktop';
import IconCurrency from 'components/icons/currency';
import IconCheck from 'components/icons/check';
import IconRobot from 'components/icons/robot';

// style
import '../index.scss';

const StatsOC = () => {
  const loading = useSelector((state) => state.loading.loading);
  const auth = useSelector((state) => state.auth.auth);
  const stats = useSelector((state) => state.stats.stats);
  const statsOCFilters = useSelector((state) => state.stats.statsOCFilters);
  const dispatch = useDispatch();

  let today = new Date();
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  let dateMin = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 366);
  if (parseInt(auth.admin) === 1) dateMin = new Date('2019-08-01');

  const [disabled, setDisabled] = useState();
  const initFilters = {
    ...statsOCFilters,
    affiliate_id: statsOCFilters.affiliate_id ? statsOCFilters.affiliate_id : '',
    date_ini: statsOCFilters.date_ini ? statsOCFilters.date_ini : dateFormat(firstDayOfMonth),
    date_min_ini: dateFormat(dateMin),
    date_min_end: dateFormat(dateMin),
    date_max_ini: dateFormat(today),
    date_max_end: dateFormat(today),
    date_end: statsOCFilters.date_end ? statsOCFilters.date_end : dateFormat(today),
    date_date: dateFormat(today),
    device: statsOCFilters.device ? statsOCFilters.device : '',
    afno: statsOCFilters.afno ? statsOCFilters.afno : '',
    country: statsOCFilters.country ? statsOCFilters.country : '',
    payment_type: statsOCFilters.payment_type ? statsOCFilters.payment_type : '',
    provider: statsOCFilters.provider ? statsOCFilters.provider : '',
    discounts: statsOCFilters.discounts ? statsOCFilters.discounts : 0,
    sitemode: statsOCFilters.sitemode ? statsOCFilters.sitemode : 'ALL',
    whitelabel: statsOCFilters.whitelabel ? statsOCFilters.whitelabel : '',
    anonymous_fidelity: statsOCFilters.anonymous_fidelity ? statsOCFilters.anonymous_fidelity : '',
    registered_fidelity: statsOCFilters.registered_fidelity ? statsOCFilters.registered_fidelity : [],
    status: statsOCFilters.status ? statsOCFilters.status : '1',
    attempt: statsOCFilters.attempt ?? '',
    method: 'STATS',
    admin: '',
    pager: {
      page: '1',
      items: '999',
    },
  };

  const [filters, setFilters] = useState(initFilters);
  const [localFilters, setLocalFilters] = useState(initFilters);

  const getData = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    OcStatsService.getStats(filters)
      .then((responseData) => {
        if (responseData.message.validation_errors) {
          toast.warn('Error de validación de campos');
        } else if (responseData.status === 'Accepted') {
          dispatch({ type: 'SET_STATS', stats: responseData.message, statsOCFilters: filters });
        } else {
          toast.warn('Ocurrió un error con su consulta, por favor ponte en contacto con el equipo de soporte');
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));

    //getStats(filters, auth.token, dispatch);
  }, [dispatch, filters]);

  useEffect(getData, [getData]);

  const getOCStats = (e) => {
    e.preventDefault();
    setFilters(localFilters);
  };

  const handleChangeInitialDate = (date) => {
    let today = new Date();
    let dateMax = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 366);
    if (dateMax > today) dateMax = today;
    setLocalFilters({
      ...localFilters,
      date_ini: date ? dateFormat(date) : '',
      date_max_end: dateMax ? dateFormat(dateMax) : '',
      date_min_end: date ? dateFormat(date) : '',
    });
  };

  const handleChangeFinalDate = (date) => {
    let dateMin = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 366);
    setLocalFilters({
      ...localFilters,
      date_end: date ? dateFormat(date) : '',
      date_min_ini: dateMin ? dateFormat(dateMin) : '',
      date_max_ini: date ? dateFormat(date) : '',
    });
  };

  const handleChangeRang = (e) => {
    const { value } = e.target;
    let _disabled = true;
    switch (value) {
      case '1':
        let today = new Date();
        setLocalFilters({
          ...localFilters,
          date_ini: dateFormat(today),
          date_end: dateFormat(today),
          date_min_ini: dateFormat(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 366)),
          date_min_end: dateFormat(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 366)),
          date_max_ini: dateFormat(today),
          date_max_end: dateFormat(today),
        });
        break;
      case '2':
        let yesteday = new Date(Date.now() - 86400000);
        setLocalFilters({
          ...filters,
          date_ini: dateFormat(yesteday),
          date_end: dateFormat(yesteday),
        });
        break;
      case '3':
        let last7days = new Date(new Date().setDate(new Date().getDate() - 7));
        setLocalFilters({
          ...localFilters,
          date_ini: dateFormat(last7days),
          date_end: dateFormat(new Date()),
        });
        break;
      case '4':
        let last30days = new Date(new Date().setDate(new Date().getDate() - 30));
        setLocalFilters({
          ...localFilters,
          date_ini: dateFormat(last30days),
          date_end: dateFormat(new Date()),
        });
        break;
      case '5':
        let currenMonth = new Date();
        let firstDayOfMonth = new Date(currenMonth.getFullYear(), currenMonth.getMonth(), 1);
        let lastDayOfMonth = new Date(currenMonth.getFullYear(), currenMonth.getMonth() + 1, 0);
        setLocalFilters({
          ...localFilters,
          date_ini: dateFormat(firstDayOfMonth),
          date_end: dateFormat(lastDayOfMonth),
        });
        break;
      case '6':
        let lastMonth = new Date();
        let firstDayOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth() - 1, 1);
        let lastDayOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth() - 1 + 1, 0);
        setLocalFilters({
          ...localFilters,
          date_ini: dateFormat(firstDayOfLastMonth),
          date_end: dateFormat(lastDayOfLastMonth),
        });
        break;
      case '7':
        let firstMonthOfYear = new Date(new Date().getFullYear(), 0, 1);
        let lastMonthOfYear = new Date(new Date().getFullYear(), 11, 31);
        setLocalFilters({
          ...localFilters,
          date_ini: dateFormat(firstMonthOfYear),
          date_end: dateFormat(lastMonthOfYear),
        });
        break;
      case '8':
        let firstMonthOfLastYear = new Date(new Date().getFullYear() - 1, 0, 1);
        let lastMonthOfLastYear = new Date(new Date().getFullYear() - 1, 11, 31);
        setLocalFilters({
          ...localFilters,
          date_ini: dateFormat(firstMonthOfLastYear),
          date_end: dateFormat(lastMonthOfLastYear),
        });
        break;
      default:
        _disabled = false;
    }
    setDisabled(_disabled);
  };

  const handleOnChange = (e) => {
    const { value, name, checked } = e.target;
    let _value = name === 'discounts' ? checked : value;

    if (name === 'payment_type') {
      setLocalFilters({
        ...localFilters,
        [name]: _value,
        provider: '',
      });
    } else {
      setLocalFilters({
        ...localFilters,
        [name]: _value,
      });
    }
  };

  //if (loading) return <Loading />;
  return (
    <div className="stats">
      {!isEmpty(stats) && (
        <div className="statsolecams-content">
          <Card icon={<IconStatsOC color="#4b5056" />} title={<FormattedMessage id="statistics-resum-title-olecams" />}>
            <div className="stats-ol">
              <Form
                disabled={disabled}
                handleChangeInitialDate={handleChangeInitialDate}
                handleChangeFinalDate={handleChangeFinalDate}
                handleChangeRang={handleChangeRang}
                /* handleChangePay={this.handleChangePay}
                handleChangePaymentProvider={this.handleChangePaymentProvider}
                handleChangeCountry={this.handleChangeCountry}
                handleChangeFont={this.handleChangeFont} */
                handleOnChange={handleOnChange}
                getStats={getOCStats}
                filters={localFilters}
              />
            </div>
          </Card>

          {!loading && (
            <>
              <h2 className="text-center mb-3">
                <span className="badge badge-secondary">
                  <FormattedMessage id="statistics-table-total" />
                </span>
              </h2>
              <div className="row">
                <div className="col">
                  <Card icon={<IconUserDesktop color="#4b5056" />} title={<FormattedMessage id="statistics-table-register" />}>
                    <div className="text-center mb-0 card-title h5">{stats.summary.signin}</div>
                  </Card>
                </div>
                {parseInt(auth.admin) === 1 && (
                  <>
                    <div className="col">
                      <Card icon={<IconRobot color="#4b5056" />} title={<FormattedMessage id="statistics-table-frau" />}>
                        <div className="text-center mb-0 card-title h5">{stats.summary.fraudulentDoi}</div>
                      </Card>
                    </div>
                    <div className="col">
                      <Card icon={<IconCheck color="#4b5056" />} title={<FormattedMessage id="statistics-table-valid" />}>
                        <div className="text-center mb-0 card-title h5">{stats.summary.validatedDoi}</div>
                      </Card>
                    </div>
                  </>
                )}
                <div className="col">
                  <Card icon={<IconFirstBuy color="#4b5056" />} title={<FormattedMessage id="statistics-table-first" />}>
                    <div className="text-center mb-0 card-title h5">{stats.summary.firstPurchasesCount}</div>
                    <div className="text-center mb-0 card-text">{formatPrice(stats.summary.firstPurchasesAmount)}</div>
                  </Card>
                </div>
                <div className="col">
                  <Card icon={<IconRefactor color="#4b5056" />} title={<FormattedMessage id="statistics-table-refact" />}>
                    <div className="text-center mb-0 card-title h5">{stats.summary.upsellPurchasesCount}</div>
                    <div className="text-center mb-0 card-text">{formatPrice(stats.summary.upsellPurchasesAmount)}</div>
                  </Card>
                </div>
                <div className="col">
                  <Card icon={<IconCurrency color="#4b5056" />} title={<FormattedMessage id="statistics-table-gan" />}>
                    <div className="text-center mb-0 card-title h5">{stats.summary.totalPurchasesCount}</div>
                    <div className="text-center mb-0 card-text">{formatPrice(stats.summary.totalPurchasesAmount)}</div>
                  </Card>
                </div>
                {parseInt(auth.admin) === 1 && (
                  <>
                    <div className="col">
                      <Card
                        icon={<FontAwesomeIcon size="lg" style={{ cursor: 'initial', opacity: '.6' }} icon={faGift} />}
                        title={<FormattedMessage id="sidebar-setting-discounts" />}>
                        <div className="text-center mb-0 card-title h5">{stats.summary.totalDiscountsCount}</div>
                        <div className="text-center mb-0 card-text">{formatPrice(stats.summary.totalDiscountsAmount)}</div>
                      </Card>
                    </div>
                    {/* <div className="col">
                      <Card
                        icon={<FontAwesomeIcon size="lg" style={{ cursor: 'initial', opacity: '.6' }} icon={faStar} />}
                        title={'Fidelidad Registrado'}>
                        <div className="text-center mb-0 card-title h5">{stats.summary.totalRegisteredFidelityCount}</div>
                        <div className="text-center mb-0 card-text">{formatPrice(stats.summary.totalRegisteredFidelityAmount)}</div>
                      </Card>
                    </div> */}
                  </>
                )}
              </div>
              <hr />
              {/*<h2 className="text-center mb-3"> <span className="badge badge-secondary">{translate('herramientas-by-years')}</span></h2>
              <div className="table-content">
                  <TableYear
                      stats={stats.years}
                      tableFilter={this.tableFilter}
                      admin={this.props.auth && this.props.auth.admin}
                  />
              </div>

              <hr />
              <h2 className="text-center mb-3"> <span className="badge badge-secondary">{translate('herramientas-by-months')}</span></h2>
              <div className="table-content">
                  <TableMonth
                      stats={stats.months}
                      tableFilter={this.tableFilter}
                      admin={this.props.auth && this.props.auth.admin}
                  />
              </div>

              <hr />*/}
              <h2 className="text-center mb-3">
                <span className="badge badge-secondary">{<FormattedMessage id="herramientas-by-days" />}</span>
              </h2>
              <div className="table-content">
                <TableDays stats={stats} />
              </div>
              <Source />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default StatsOC;
