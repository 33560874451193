import { useEffect } from 'react';
import SwaggerUI from './swagger';
import { useAuth } from 'contexts/auth';
import { useHistory } from 'react-router-dom';

const AffiliateDocs = () => {
  const { logged } = useAuth();
  const history = useHistory();

  useEffect(() => {
    !logged && history.push('/login');
  }, [logged, history]);

  return (
    <div>
      <SwaggerUI url="/docs/api-docs.json" />
    </div>
  );
};

export default AffiliateDocs;
