export const PUBLIC = {
  HOME: '/home',
  RATES: '/rates',
  PRODUCTS: '/products',
  WHITE_BRAND: '/white_brand',
  CONTACT: '/contact',
  LEGAL_RECORD: '/legal-record',
  LOGIN: '/login',
  REGISTER: '/registro-afiliado',
  EMAIL_RECOVERY: '/email-recovery',
  RECOVERY: '/recovery',
  LEGAL_CONDITIONS: 'https://legal.promocionesweb.com/condiciones.php',
  LEGAL_ADVICE: '/aviso-legal',
  PRIVACY_POLICY: '/politica-privacidad',
  COOKIE_POLICY: '/politica-cookies',
  API_DOCS: '/api/documentation',
  BROKERS_API_DOCS: '/broker/api/documentation',
  WL_LINKS: {
    VICIOCAMS: 'https://www.viciocams.com/',
    WEBCAMS_PUTALOCURA: 'https://webcams.putalocura.com/es',
    WEBCAMS_MUYZORRAS: 'https://webcams.muyzorras.com/',
    CAMS_ORGASMATRIX: 'https://cams.orgasmatrix.com/es',
    LIVE_CUMLOADER: 'https://live.cumlouder.com/es',
    VILLAWEBCAMS: 'https://www.villawebcams.com/es',
  },
};

export const OTHERS = {
  WORK_WITH_US: 'https://www.trabajaconwebcam.com/',
};
