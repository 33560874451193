import { Fragment, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { isEmpty } from 'libs/lodashAlt';

import LandingService from 'shared/landing-service';

//Component
import Card from 'components/card';
import Button from 'components/button';
import DataIcon from 'components/icons/data';
import Mobile from 'components/icons/movil';
import Desktop from 'components/icons/computer';
import Loading from 'components/loading';

// style
import './index.scss';
import { FormattedMessage } from 'react-intl';
//import { getLogins } from 'actions/stats'

const Landings = () => {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const auth = useSelector((state) => state.auth.auth);

  const [view, setView] = useState('DESKTOP');
  const [code, setCode] = useState('');
  const [showCode, setShowCode] = useState('');
  /*const [type, setType] = useState('olecams');
  const [login, setLogin] = useState('');
  const [logins, setLogins] = useState([]);*/

  const getData = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    LandingService.list({ page: 1, perPage: 99, filters: [] })
      .then((data) => {
        if (data.status !== 'Failed') {
          setData(data.message.data);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    //getLandingList(auth.token, dispatch);
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  //const changeView = (view) => setView(view);

  const handleOnChange = (e) => {
    // Remove the "-" char to avoid affiliate enter this symbol. This MUST be done to AFNO Version 3 works
    let _code = e.target.value.trim().replace(/( )?-( )?/g, '');
    setCode(_code);
  };

  const saveData = (e) => {
    e.preventDefault();
    setShowCode(`-${code}`);
  };

  return (
    <>
      {(isEmpty(data) || loading) && <Loading />}

      {!isEmpty(data) && (
        <div className="landing-content">
          <Card icon={<DataIcon />} title={<FormattedMessage id="promo-landing-title-info" />}>
            <div className="text-content">
              <p>
                <FormattedMessage id="promo-landing-text-one" />
              </p>
              <p>
                <FormattedMessage id="promo-landing-text-two" />
              </p>
              <p>
                <FormattedMessage id="promo-landing-text-tree" />
              </p>
              <p>
                <FormattedMessage id="promo-landing-text-four" />
              </p>
              <p>
                <FormattedMessage id="promo-landing-text-five" />
              </p>
              <p>
                <FormattedMessage id="promo-landing-text-six" />
              </p>
              <p>
                <FormattedMessage id="promo-landing-text-seven" />
              </p>
              <p>
                <FormattedMessage id="promo-landing-text-eight" />
              </p>
            </div>
          </Card>
          <Card icon={<DataIcon />} title={<FormattedMessage id="promo-landing-title-comp" />}>
            <div className="form-content">
              <form>
                <div className="form-group">
                  <label>
                    <FormattedMessage id="promo-landing-form-label" />
                  </label>
                  <input type="text" className="form-control" name="code" value={code} onChange={handleOnChange} />
                </div>
                {/* <div className="form-group">
                                        <label> {translate("promo-landing-form-type")}</label>
                                        <select className="form-control" name="type" onChange={this.handleOnChangeType}>
                                                <option value="olecams">Olecams</option>
                                                <option value="vce">Vce</option>
                                        </select>
                                    </div> */}
                {/* {
                                        this.state.logins.length > 0 &&
                                        <div className="form-group">
                                            <label> {translate("promo-landing-form-login")}</label>
                                            <select className="form-control" name="login" onChange={this.handleChangeLogin}>
                                                {
                                                    this.state.logins.map((item, index) => {
                                                        if(item.login){
                                                            return  <option key={index} value={item.login}>{item.login}</option>
                                                        }
                                                    })
                                                }
                                            </select>
                                        </div>
                                    } */}
                <div className="button-content">
                  <Button rounded onClick={(e) => saveData(e)} align="center">
                    <FormattedMessage id="promo-landing-form-button" />
                  </Button>
                </div>
              </form>
            </div>
          </Card>
          <div className="spcial-card">
            <Card header={false}>
              <div className="view-content">
                <span onClick={(e) => setView('MOBILE')} className={classNames({ active: view === 'MOBILE' })}>
                  <Mobile color="#4b5056" /> <FormattedMessage id="promo-landing-mobile" />
                </span>
                <span onClick={(e) => setView('DESKTOP')} className={classNames({ active: view === 'DESKTOP' })}>
                  <Desktop color="#4b5056" /> <FormattedMessage id="promo-landing-desktop" />
                </span>
              </div>
              <div className="link-content">
                {data.map((item, index) => {
                  //     let url = `${item.URL}?${item.TYPE_URL === 'OC' ? 'afno' : 'login'}=${item.TYPE_URL === 'OC' ? `1-${auth.id}` : `${this.state.login}`}`
                  let url = `${item.URL}?afno=1-${auth.id}${showCode}`;
                  if (view === item.CATEGORY) {
                    return (
                      <Fragment key={index}>
                        <a key={index} href={url} target="_blank" rel="noopener noreferrer">
                          {url}
                        </a>
                        <br />
                      </Fragment>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default Landings;
