import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, CardGroup, Container, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

import Loading from 'components/loading';
import IconSettings from 'components/icons/settings';

import './index.scss';

const BrokerDocs = () => {
  let loading = useSelector((state) => state.loading.loading);
  if (loading) return <Loading />;

  return (
    <Container fluid>
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>
              <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> <FormattedMessage id="sidebar-documentation" />
            </Card.Header>
            <Card.Body>
              <CardGroup className="docs-cards text-center">
                <Card>
                  <Card.Body>
                    <Card.Title>
                      <FormattedMessage id="global-api-content-brokers-quick-start" />
                    </Card.Title>
                    <Card.Text>
                      <FormattedMessage id="global-api_quick_start_documentation" />
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Link
                      to={{ pathname: '/docs/broker/api-videochatprovider-documentation .pdf' }}
                      role="button"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button">
                      <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
                      <FormattedMessage id="global-view-docs" />
                    </Link>
                  </Card.Footer>
                </Card>
                {/* <Card>
                  <Card.Body>
                    <Card.Title>{translate('global-broker-docs-api-vcp-title')}</Card.Title>
                    <Card.Text>{translate('global-broker-docs-api-vcp-description')}</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Link
                      to={{ pathname: '/docs/broker/brokers-documentacion_implement_vcp.1.1.0.pdf' }}
                      role="button"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button">
                      <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
                      {translate('global-view-docs')}
                    </Link>
                  </Card.Footer>
                </Card> */}
                <Card>
                  <Card.Body>
                    <Card.Title>
                      <FormattedMessage id="global-broker-docs-brokersys-title" />
                    </Card.Title>
                    <Card.Text>
                      <FormattedMessage id="global-broker-docs-brokersys-description" />
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Link
                      to={{ pathname: '/broker/api/documentation' }}
                      role="button"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button">
                      <IconSettings />
                      <FormattedMessage id="global-view-docs" />
                    </Link>
                  </Card.Footer>
                </Card>
                {/*<Card>
                    <Card.Body>
                        <Card.Title>{translate('global-broker-docs-brokersys-title')}</Card.Title>
                        <Card.Text>{translate('global-broker-docs-brokersys-description')}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <Link 
                            to={{ pathname: "/docs/broker/brokers-documentacion.pdf" }}
                            role="button"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="button"
                        >
                            <FontAwesomeIcon icon={faFilePdf} className="mr-2" />{translate('global-view-docs')}
                        </Link>
                    </Card.Footer>
                </Card>*/}
              </CardGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BrokerDocs;
